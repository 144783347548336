import Vue            from 'vue';
import ErrorReporting from './ErrorReporting';

const Rollbar = require('vue-rollbar');

class RollbarErrorReporter extends ErrorReporting {
    setOpts(vm) {
        if (! vm || ! vm.$store) {
            return;
        }

        if (this.scopeSet !== true) {
            if (vm && typeof vm.$store !== 'undefined') {
                try {
                    if (vm.$store.state.Tracking.logRocketSettings.sessionUrl && vm.$store.state.Tracking.logRocketSettings.sessionUrl) {
                        this.$rollbar.configure({
                            transform: function (obj) {
                                obj.sessionURL = vm.$store.state.Tracking.logRocketSettings.sessionUrl;
                                return obj;
                            },
                        });
                    }
                } catch (e) {

                }
            }

            this.scopeSet = true;
        }
    }

    fromHandler(exception, vm, context) {
        this.setOpts(vm);
        vm.$rollbar.error(exception, context);
    }

    fromWarnHandler(message, vm, context) {
        this.setOpts(vm);
        vm.$rollbar.warn(message, context);
    }

    exception(exception, context) {
        if (! this.$app || ! this.$app.$rollbar) {
            return;
        }

        this.setOpts(this.$app, context);
        this.$app.$rollbar.error(exception, context);
    }

    critical(message, context) {
        if (! this.$app || ! this.$app.$rollbar) {
            return;
        }

        this.setOpts(this.$app, context);
        this.$app.$rollbar.critical(message, context);
    }

    error(message, context) {
        if (! this.$app || ! this.$app.$rollbar) {
            return;
        }

        this.setOpts(this.$app, context);
        this.$app.$rollbar.error(message, context);
    }

    warn(message, context) {
        if (! this.$app || ! this.$app.$rollbar) {
            return;
        }

        this.setOpts(this.$app, context);
        this.$app.$rollbar.warning(message, context);
    }

    info(message, context) {
        if (! this.$app || ! this.$app.$rollbar) {
            return;
        }

        // this.$app.$rollbar.info(message, context);
    }

    debug(message, context) {
        if (! this.$app || ! this.$app.$rollbar) {
            return;
        }

        //this.$app.$rollbar.debug(message, context);
    }

    addBreadcrumb(message, context, breadcrumbContext) {
        if (! this.$app || ! this.$app.$rollbar) {
            return;
        }

        if (! context || typeof context !== 'object') {
            context = {};
        }

        if (! breadcrumbContext || typeof breadcrumbContext !== 'object') {
            breadcrumbContext = {};
        }

        this.$app.$rollbar.captureEvent({
            message,
            ...context,
            ...breadcrumbContext,
        }, breadcrumbContext.level || 'info');
    }
}

Vue.use(Rollbar, {
    accessToken:                process.env.VUE_APP_ROLLBAR_TOKEN,
    captureUncaught:            true,
    captureUnhandledRejections: true,
    enabled:                    true,
    source_map_enabled:         true,
    environment:                process.env.VUE_APP_ENV,
    payload:                    {
        client: {
            javascript: {
                code_version:          process.env.VUE_APP_APP_VERSION || '1.0',
                source_map_enabled:    true,
                guess_uncaught_frames: true,
            },
        },
    },
});

const rollbarErrorReporterInstance = new RollbarErrorReporter();

window.rollbar = Vue.rollbar;

export default rollbarErrorReporterInstance;
